#buttons {

    & button{
      &:active {
        filter: opacity(0.8);
      }
    }

    & #button {
      border: none;
      border-radius: 10px;
      font-size: 10pt;
      font-weight: 600;
      padding: 10px 20px;
      width: fit-content;
      gap: 10px;
      align-items: center;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      background-color: var(--primary-white);
      color: var( --primary-blue);
      cursor: pointer;
      
      & span {
        font-size: 20px;
      }
      
      &:active {
        filter: opacity(0.8);
      }
    }
}

.wap-contact {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  padding: 30px 50px 30px 0px;
  border-bottom: 1px solid grey;

  & img {
      background-size: cover;
      height: 50px;
      width: 50px;
      border: none;
      outline: none;
      cursor: pointer;
  }

  & img:hover {
      opacity: 0.6;
  }

  & label {
      color: var(--primary-gold);
      font-size: 16px;
  }
}